<template>
  <div class="patient-information">
    <div class="row">
      <div class="col-lg-7">
        <ClinicianPatientOverview v-if="isPatient" />
        <ClinicianRespondentOverview v-if="!isPatient" />
      </div>
    </div>
  </div>
</template>

<style>

</style>
<script>
import ClinicianPatientOverview from '@/components/clinician/patients/ClinicianPatientOverview'
import ClinicianRespondentOverview from '@/components/clinician/patients/ClinicianRespondentOverview'
import { RespondentHelper } from '@/mixins/RespondentHelper'
import { mapGetters } from 'vuex'

export default {
  name: 'ClinicianPatientInformation',
  components: {
    ClinicianPatientOverview,
    ClinicianRespondentOverview
  },
  mixins: [RespondentHelper],
  props: {},
  computed: {
    ...mapGetters({
      activeAssignment: 'getActiveAssignment'
    }),
    isPatient () {
      return this.activeAssignment ? this.isPatientRelation(this.activeAssignment.patient_relation_id) : false
    }
  }
}
</script>
