<template>
  <div class="patient-overview">
    <div class="header">
      <h3>Overview</h3>
    </div>
    <div class="form">
      <clinician-respondent-form-wrapper :cca-id="ccaId" />
    </div>
  </div>
</template>

<style>

</style>
<script>
import { Helpers } from '@/mixins/Helpers'
import ClinicianRespondentFormWrapper from '@/components/clinician/patients/ClinicianRespondentFormWrapper'

export default {
  name: 'ClinicianRespondentOverview',
  components: {
    ClinicianRespondentFormWrapper
  },
  mixins: [Helpers],
  props: {},
  data: () => {
    return {}
  },
  computed: {
    ccaId () {
      return this.$getDecodedId(this.$route.params.ccaId)
    }
  },
  created () {
  },
  methods: {
  }
}
</script>
